import { useIsFetching, useQuery } from "react-query";
import { getClient, listAssignee } from "../../core/apis";
import { SIZE, Tag, VARIANT } from "baseui/tag";
import { humanizeBudget, parseEtcRequestType, parseGroup, parseProjectCategory, parseProjectType } from "../../core/utils";
import React, { useEffect, useContext } from "react";
import { styled } from "baseui";
import { Block } from "baseui/block";
import FlexBlock from "../FlexBlock";
import { Spinner } from "baseui/spinner";
import { LabelLarge, LabelSmall as BaseLabelSmall, LabelXSmall as BaseLabelXSmall } from "baseui/typography";
import { Drawer } from "baseui/drawer";
import { Button } from "baseui/button";
import parsePhoneNumber from "libphonenumber-js";
import { useEditor } from "@milkdown/react";

const LabelXSmall = (props) => {
    return <BaseLabelXSmall paddingTop="1.6rem" paddingBottom="0.4rem" fontWeight="300" {...props} />;
};

const LabelSmall = (props) => {
    return <BaseLabelSmall paddingLeft="5px" paddingTop="6px" {...props} />;
};

export default function RequsetClient({ paper }) {
    const { client: clientId, group, project_categories: projectCategories, domain, request_type: requestType, request_description, description, budget, client_ip } = paper;
    const { data: client, isSuccess: clientFetched } = useQuery(["clientDetail", { id: clientId }], getClient, { enabled: !!clientId, refetchOnMount: false });
    const { data: assignees, isSuccess: assigneesFetched } = useQuery(["assignees", { clientId, paperId: paper.id }], listAssignee, { enabled: !!clientId, refetchOnMount: false });
    const fetched = Boolean(clientFetched && assigneesFetched);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerContent, setDrawerContent] = React.useState({
        name: "Unknown",
        phone: "",
        department: "",
        email: "",
        position: "",
        id: null,
    });

    if (!fetched) {
        return (
            <FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
                <Spinner $size="50px" $borderWidth="5px" $color="#000" />
            </FlexBlock>
        );
    }


    return (
        <></>
    );
}

function ProjectContent({ projectCategories, domain, startDate, endDate, scheduleAdjustable }) {
    return (
        <>
            <RowContainer>
                <Row>
                    <LabelXSmall>일정</LabelXSmall>
                    <LabelSmall>
                        {startDate && startDate.split("-").join(".")}
                        <Unit /> ~ {endDate && endDate.split("-").join(".")}
                    </LabelSmall>
                </Row>
                <Row>
                    <LabelXSmall>일정 조율 가능</LabelXSmall>
                    <LabelSmall>{scheduleAdjustable ? "YES" : "NO"}</LabelSmall>
                </Row>
            </RowContainer>
            <RowContainer>
                <Row>
                    <LabelXSmall>업무범위</LabelXSmall>
                    <Block className="tag-wrap">
                        {projectCategories?.map((row, index) => (
                            <Tag
                                variant={VARIANT.outlined}
                                closeable={false}
                                size={SIZE.small}
                                key={index}
                                overrides={{
                                    Root: {
                                        style: {
                                            minWidth: "40px",
                                            textAlign: 'center',
                                            border: '1.5px solid #000',
                                            height: 'unset',
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            justifyContent: "center",
                                            padding: "4px 12px",
                                            marginLeft: '0',
                                            "@media screen and (max-width: 768px)": {
                                                fontSize: "12px",
                                            },
                                        },
                                    },
                                }}>
                                {parseProjectCategory(row)}
                            </Tag>
                        ))}
                    </Block>
                </Row>
                <Row>
                    <LabelXSmall>업무유형</LabelXSmall>
                    <Block className="tag-wrap">
                        {domain?.map((row, index) => (
                            <Tag
                                variant={VARIANT.solid}
                                closeable={false}
                                size={SIZE.small}
                                key={index}
                                overrides={{
                                    Root: {
                                        style: {
                                            minWidth: "40px",
                                            textAlign: 'center',
                                            border: '1.5px solid #000',
                                            height: 'unset',
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            justifyContent: "center",
                                            padding: "4px 12px",
                                            marginLeft: '0',
                                            "@media screen and (max-width: 768px)": {
                                                fontSize: "12px",
                                            },
                                        },
                                    },
                                }}>
                                {parseProjectType(row)}
                            </Tag>
                        ))}
                    </Block>
                </Row>
            </RowContainer>
        </>
    );
}

function EtcContent({ description }) {
    return (
        <>
            <LabelXSmall>추가업무내용</LabelXSmall>
            <LabelSmall style={{ whiteSpace: "pre-wrap" }}>{description || "없음"}</LabelSmall>
        </>
    );
}

const GridWrapper = styled("div", {
    width: "765px",
    maxWidth: "100%",
    flex: "1 0 auto",
});

const RowContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
});
const Row = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
    paddingRight: "15px",
    boxSizing: "border-box",
});
const Unit = styled("span", {
    display: "inline",
    "@media screen and (max-width: 768px)": {
        display: "block",
        height: "6px",
    },
});
